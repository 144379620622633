import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input'
import { Select } from 'components/Select'
import { addDialog, removeDialog } from 'internal/redux'
import { ReactNode, useRef } from 'react'
import { IconType } from 'react-icons'
import { HiOutlineExclamation, HiOutlineInformationCircle } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

type UseShowDialog = {
  title: string
  msg?: string | ReactNode
  btnText?: string
  confirm?: any
  confirmPhrase?: string
  confirmPlaceholder?: string
  confirmType?: 'text' | 'date'
  confirmCaseSensitive?: boolean
  icon?: IconType
  inputMaxLength?: number
  defaultValue?: string
  variant?: 'primary' | 'danger'
  options?: { label: string; value: any }[]
  onCancel?: (() => void) | false
  onConfirm?: Function
}

export const useShowDialog = (options: UseShowDialog) => {
  const {
    btnText,
    confirmPhrase,
    confirmPlaceholder,
    confirmCaseSensitive = true,
    inputMaxLength,
    defaultValue,
    confirmType = 'text',
    title,
    icon,
    variant: variantType = 'danger',
    onCancel,
    onConfirm,
  } = options
  const id = Date.now()
  const dispatch = useDispatch()
  const handleRemove = () => dispatch(removeDialog(id))
  // const inputRef = useRef<HTMLInputElement>(null)
  const primary = {
    iconBg: 'bg-blue-50 dark:bg-dark-500',
    icon: 'text-blue-600 dark:text-blue-400',
    Icon: HiOutlineInformationCircle,
  }
  const danger = {
    iconBg: 'bg-red-50 dark:bg-dark-500',
    icon: 'text-red-600 dark:text-red-400',
    Icon: HiOutlineExclamation,
  }
  const variants = { primary, danger }
  const variant = variants[variantType]
  const valueRef = useRef<string>(null)
  const Icon = icon || variant.Icon

  const show = (...args: any) => {
    return new Promise<boolean | string>(resolve => {
      const handleClose = () => {
        handleRemove()
        resolve(false)
        valueRef.current = null
      }

      const cb = () => {
        if (selectOptions && !valueRef.current) {
          return
        }

        onConfirm?.(...args)
        handleRemove()
        resolve(confirmPhrase ? valueRef.current ?? true : valueRef.current || true)
        valueRef.current = null
      }

      const selectOptions = options.options

      const payload = {
        id,
        content: (
          <div className="dark:bg-dark-800 inline-block overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
            <div className="dark:bg-dark-700 bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div
                  className={classNames([
                    'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    variant.iconBg,
                  ])}
                >
                  <Icon className={classNames(['h-6 w-6', variant.icon])} />
                </div>
                <div className="mt-3 space-y-2 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200" id="modal-title">
                    {title}
                  </h3>
                  <div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">{options.confirm || options.msg}</div>
                  </div>
                  {!!options.confirm && (
                    <div>
                      <Input
                        id={`confirmInput.${id}`}
                        type={confirmType}
                        placeholder={confirmPlaceholder || ''}
                        maxLength={inputMaxLength || 500}
                        defaultValue={defaultValue || ''}
                        onChange={e => {
                          valueRef.current = e.currentTarget.value
                        }}
                      />
                    </div>
                  )}
                  {selectOptions &&
                    (selectOptions.length > 0 ? (
                      <div>
                        <Select defaultValue={''} onChange={v => (valueRef.current = v.currentTarget.value)}>
                          <option value={''} disabled>
                            Select an option
                          </option>
                          {selectOptions.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      <Select value={''} disabled>
                        <option value={''}>All options are already selected</option>
                      </Select>
                    ))}
                </div>
              </div>
            </div>
            <div className="dark:bg-dark-700 gap-2 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <div className="inline-flex items-center gap-2">
                {onCancel !== false && (onCancel || (!onCancel && !onConfirm)) && (
                  <Button onClick={handleClose}>Cancel</Button>
                )}
                <Button
                  variant={variantType}
                  // disabled={!!options.options?.length && !value}
                  onClick={() => {
                    const input = document.getElementById(`confirmInput.${id}`) as HTMLInputElement

                    if (options.confirm && confirmPhrase) {
                      const isMatch = confirmCaseSensitive
                        ? input.value === confirmPhrase
                        : input.value.toLowerCase() === confirmPhrase.toLowerCase()
                      if (!isMatch) return
                    }

                    cb()
                  }}
                >
                  {onCancel || (!onCancel && !onConfirm) ? btnText || 'Confirm' : 'Close'}
                </Button>
              </div>
            </div>
          </div>
        ),
      }
      dispatch(addDialog(payload))
    })
  }

  return { show }
}
