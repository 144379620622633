import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { OwnerLink } from 'components/admin/OwnerLink'
import { ProviderLink } from 'components/admin/ProviderLink'
import { DescriptionDetails } from 'components/DescriptionDetails'
import { DescriptionTerm } from 'components/DescriptionTerm'
import { DocumentModalFields } from 'components/Documents/DocumentModalField'

import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { AccountLabel } from 'components/Labels/AccountLabel'
import { DocumentTypeLabel } from 'components/Labels/DocumentTypeLabel'
import RejectionReasons from 'components/RejectionReasons'
import { RequireFlag } from 'components/RequireFlag'
import { RequireRole } from 'components/RequireRole/RequireRole'
import { CopyableText } from 'components/Text'
import { shouldShowVINs } from 'helpers/document'
import { useFlags } from 'hooks/useFlagsmith'
import { formatTime } from 'internal/util/dates'
import Link from 'next/link'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'
import { Document } from 'types/Document'
import { DocumentAttestationDetails } from './DocumentAttestationDetails'
import { DocumentModalVehiclesList } from './DocumentModalVehiclesList'
import { DocumentStatusBadge } from './DocumentStatusBadge'
import { DocumentViewer } from './DocumentViewer'
import { HeldDocumentActions } from './HeldDocumentActions'

type DocumentModalContentProps = {
  activeTab?: 'Document' | 'Attestation'
  document: Document
  rotation?: number
  baseUrl?: string
  linkable?: boolean
  hideInfo?: boolean
  showVehicleInfo?: boolean
  onSetActive?: (id: string) => void
  reload?: () => void
  onClose: () => void
}

export function DocumentModalContent(props: DocumentModalContentProps) {
  const { activeTab = 'Document', baseUrl = '', document: doc, rotation = 0, hideInfo, reload, onClose } = props
  const flags = useFlags(['coi'])

  if (!doc) {
    return null
  }

  const isCoiEnabled = flags?.coi?.enabled
  const showCoiAccordion = isCoiEnabled && doc?.attestation
  const showVehiclesAccordion = shouldShowVINs(doc?.extractedData) && doc?.status === 'approved'

  return (
    <div className="dark:bg-dark-800 flex h-0 flex-1 flex-col overflow-auto border-t border-gray-200 bg-white dark:border-gray-700 sm:flex-row">
      <div className="dark:bg-dark-900 min-h-80 flex flex-1 overflow-auto border-b border-gray-200 bg-gray-200 dark:border-gray-700 sm:border-b-0 sm:border-r">
        <DocumentViewer
          documentId={doc.id}
          activeTab={activeTab}
          mimeType={doc.mimeType}
          rotation={doc.rotation + rotation}
        />
      </div>
      <RequireRole deny="insurance-producer">
        <Box className={classNames('dark:bg-dark-900 w-screen max-w-md bg-gray-50', hideInfo && 'hidden')}>
          <Accordion defaultValue="details" type="single" collapsible className="flex h-full flex-col overflow-hidden">
            <AccordionItem value="details" className="flex flex-col overflow-hidden border-b data-[state=open]:flex-1">
              {!showCoiAccordion && !showVehiclesAccordion ? null : (
                <AccordionTrigger className="dark:bg-dark-600 border-b  bg-white px-4 py-3 dark:text-white">
                  Details
                </AccordionTrigger>
              )}
              <AccordionContent className="h-full overflow-auto">
                <Box className="p-4">
                  <RequireFlag flag="upload_links">
                    <HeldDocumentActions baseUrl={baseUrl} document={doc} reload={reload} onClose={onClose} />
                  </RequireFlag>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <RequireRole deny="provider">
                      <div className="sm:col-span-1">
                        <DescriptionTerm>Provider</DescriptionTerm>
                        <DescriptionDetails className="mt-2">
                          <ProviderLink baseUrl="/network" provider={doc.provider} />
                        </DescriptionDetails>
                      </div>
                    </RequireRole>
                    <div className="sm:col-span-1">
                      <DescriptionTerm>Owner</DescriptionTerm>
                      <DescriptionDetails className="mt-2">
                        <OwnerLink baseUrl={baseUrl} owner={doc.owner} />
                      </DescriptionDetails>
                    </div>
                    <div className="sm:col-span-1">
                      <DescriptionTerm>Document Type</DescriptionTerm>
                      <DescriptionDetails className="mt-2">
                        <DocumentTypeLabel name={doc.type?.name} />
                      </DescriptionDetails>
                    </div>
                    <div className="sm:col-span-1">
                      <div className="flex flex-col">
                        <DescriptionTerm>Status</DescriptionTerm>
                        <DescriptionDetails className="mt-2">
                          <DocumentStatusBadge
                            isPrimary={doc.owner?.isPrimary}
                            status={doc.status}
                            exemption={null} // If we're looking at a document, we can't have an exemption
                            className="text-md"
                          />
                        </DescriptionDetails>
                      </div>
                    </div>
                    {doc.status === 'rejected' && doc.statusReason && (
                      <div className="sm:col-span-2">
                        <div className="flex flex-col">
                          <div className="mt-3 sm:col-span-1">
                            <DescriptionTerm>Rejection Reason(s)</DescriptionTerm>
                            <DescriptionDetails className="mt-2">
                              <RejectionReasons statusReason={doc.statusReason} />
                            </DescriptionDetails>
                          </div>
                        </div>
                      </div>
                    )}

                    {(doc.status === 'rejected' || doc.status === 'approved') && (
                      <div className="sm:col-span-1">
                        <DescriptionTerm className="capitalize">{doc.status}</DescriptionTerm>
                        <DescriptionDetails className="mt-2">{formatTime(doc.decision?.createdAt)}</DescriptionDetails>
                      </div>
                    )}

                    <RequireRole deny="provider">
                      {doc.decision?.deciderType === 'user' && (
                        <div className="sm:col-span-1">
                          <DescriptionTerm>Decision Maker</DescriptionTerm>
                          <DescriptionDetails className="mt-2">
                            <AccountLabel
                              account={doc.decision.decider}
                              href={`/network/tickets/${doc.decision.assignmentId}`}
                            />
                          </DescriptionDetails>
                        </div>
                      )}
                    </RequireRole>
                    <div className="sm:col-span-1">
                      <DescriptionTerm>Uploaded</DescriptionTerm>
                      <DescriptionDetails className="mt-2">{formatTime(doc.createdAt)}</DescriptionDetails>
                    </div>

                    {doc.successor && (
                      <div className="sm:col-span-2">
                        <DescriptionTerm>Succeeded By</DescriptionTerm>
                        <DescriptionDetails className="mt-2">
                          <a className="hover:underline" onClick={() => props.onSetActive?.(doc.successor.id)}>
                            Document uploaded on {formatTime(doc.successor.createdAt)}
                          </a>
                        </DescriptionDetails>
                      </div>
                    )}
                    <DocumentModalFields extractedData={doc.extractedData} documentType={doc?.documentType} />

                    <div className="sm:col-span-2">
                      <DescriptionTerm>ID</DescriptionTerm>
                      <DescriptionDetails className="mt-2">
                        <CopyableText size="sm">{doc.id}</CopyableText>
                      </DescriptionDetails>
                    </div>
                    <RequireRole allow={['superadmin', 'manager']}>
                      <InspectionResultSection document={doc} />
                    </RequireRole>
                  </dl>
                </Box>
              </AccordionContent>
            </AccordionItem>
            {showCoiAccordion && (
              <AccordionItem value="coi" className="flex flex-col overflow-hidden data-[state=open]:flex-1">
                <AccordionTrigger className="dark:bg-dark-600 border-b bg-white px-4 py-3 dark:text-white">
                  Certificate of Insurance
                </AccordionTrigger>
                <AccordionContent className="h-full overflow-auto">
                  <DocumentAttestationDetails attestation={doc?.attestation} />
                </AccordionContent>
              </AccordionItem>
            )}
            {showVehiclesAccordion && (
              <AccordionItem value="vehicles" className="flex flex-col overflow-hidden data-[state=open]:flex-1">
                <AccordionTrigger className="dark:bg-dark-600 border-b bg-white px-4 py-3 dark:text-white">
                  Vehicles
                </AccordionTrigger>
                <AccordionContent className="h-full">
                  <DocumentModalVehiclesList document={doc} />
                </AccordionContent>
              </AccordionItem>
            )}
          </Accordion>
        </Box>
      </RequireRole>
    </div>
  )
}

function useInspectionResult(documentId: string) {
  const { data } = useQuery(INSPECTION_RESULT_QUERY, { variables: { documentId }, skip: !documentId })
  const inspectionResult = data?.inspectionResult
  return { inspectionResult }
}

const INSPECTION_RESULT_QUERY = gql`
  query DocumentInspectionResult($documentId: String!) {
    inspectionResult: inspectionResultByDocument(documentId: $documentId) {
      id
      bookingId
    }
  }
`

function InspectionResultSection({ document }: { document: Document }) {
  const { inspectionResult } = useInspectionResult(document?.id)
  if (!inspectionResult) {
    return null
  }

  return (
    <div className="sm:col-span-2">
      <DescriptionTerm>Inspection Result</DescriptionTerm>
      <DescriptionDetails className="mt-2">
        <Link href={`/network/inspections/${inspectionResult.bookingId}?vehicleId=${document.owner?.vehicleId}`}>
          <Button icon={HiOutlineClipboardDocumentList} block>
            Go to Inspection Result
          </Button>
        </Link>
      </DescriptionDetails>
    </div>
  )
}
