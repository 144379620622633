import { ApolloProvider } from '@apollo/client'
import { DialogContainer } from 'components/Dialog'
import { GlobalDocumentModal } from 'components/Documents/GlobalDocumentModal'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { setupi18n } from 'lib/strings'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Wrapper } from '../components/Wrapper/Wrapper'
import { AnalyticsProvider } from '../contexts/AnalyticsProvider'
import { RemoteConfigProvider } from '../contexts/RemoteConfigProvider'
import { SidebarProvider } from '../contexts/SidebarProvider'
import { client } from '../internal/graphql/client'
import { setGlobalActiveDocumentId, store } from '../internal/redux'
import { refreshAuth } from '../lib/api'

import { WorkspaceDocumentModal } from 'components/Documents/WorkspaceDocumentModal'
import { Redirector } from 'components/Redirector'
import { RequirementsProvider } from 'contexts/providers/RequirementsProvider'
import { load as loadIntercom } from 'helpers/intercom'
import 'maplibre-gl/dist/maplibre-gl.css'
import '../styles/ReactToastify.min.css'
import '../styles/globals.scss'

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    loadIntercom()
  }, [])

  useEffect(() => {
    const refresh = setInterval(
      () => {
        refreshAuth()
      },
      1000 * 60 * 60 * 30,
    )
    setupi18n()
    if (
      localStorage.theme === 'dark'
      // || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    return () => {
      clearInterval(refresh)
    }
  }, [router])

  useEffect(() => {
    const handleRouteChange = () => {
      // if there is a globally active document set it to null on route change
      if (!!store.getState().document.globalActiveDocumentId) {
        store.dispatch(setGlobalActiveDocumentId(null))
      }
      window.sessionStorage.setItem('prevRoute', router.asPath)
    }
    const handleRouteChangeComplete = () => {}
    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])

  return (
    <FlagsmithProvider
      options={{
        preventFetch: true,
        environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_KEY as string,
        api: 'https://flags.complicore.co/api/v1/',
      }}
      flagsmith={flagsmith}
    >
      <RemoteConfigProvider>
        <AnalyticsProvider>
          <Provider store={store}>
            <ApolloProvider client={client}>
              <SidebarProvider>
                <Wrapper bootSupport={pageProps?.options?.providers?.support !== false}>
                  <Script strategy="lazyOnload" src={'/js/dl.js'} />
                  <Head>
                    <title>Complicore</title>
                  </Head>
                  <RequirementsProvider>
                    <Redirector>
                      <Component {...pageProps} />
                    </Redirector>
                  </RequirementsProvider>
                  <WorkspaceDocumentModal />
                  <GlobalDocumentModal />
                  <ToastContainer autoClose={3000} position="top-right" pauseOnHover={true} hideProgressBar />
                  <DialogContainer />
                </Wrapper>
              </SidebarProvider>
            </ApolloProvider>
          </Provider>
        </AnalyticsProvider>
      </RemoteConfigProvider>
    </FlagsmithProvider>
  )
}

export default MyApp
